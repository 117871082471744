body {
  margin: 0;
  font-family: "QualcommNext-Regular";

}

code {
  font-family: "QualcommNext-Regular";

}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

::-webkit-scrollbar{
  width: 0.4em;
}
::-webkit-scrollbar-track{
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
}
::-webkit-scrollbar-thumb{
  background-color: rgba(97, 97, 97, 0.61);
  border: 1px solid #70809000;
  border-radius: 10px !important;
}

@font-face {
  font-family: 'QualcommNext-Italic';
  src: local('QualcommNext-Italic'), url('./fonts/Qualcomm-Next/QualcommNext-Italic.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'QualcommNext-Medium';
  src: local('QualcommNext-Medium'), url('./fonts/Qualcomm-Next/QualcommNext-Medium.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'QualcommNext-MediumItalic';
  src: local('QualcommNext-MediumItalic'), url('./fonts/Qualcomm-Next/QualcommNext-MediumItalic.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'QualcommNext-Regular';
  src: local('QualcommNext-Regular'), url('./fonts/Qualcomm-Next/QualcommNext-Regular.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'QualcommNext-Thin';
  src: local('QualcommNext-Thin'), url('./fonts/Qualcomm-Next/QualcommNext-Thin.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'QualcommNext-ThinItalic';
  src: local('QualcommNext-ThinItalic'), url('./fonts/Qualcomm-Next/QualcommNext-ThinItalic.otf') format('opentype');
  font-display: swap;
}
